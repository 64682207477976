@use "../../converSight/variables" as *;

div.conversight.main-frame {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  top: 40px;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0px 2px 8px rgb(40 41 61 / 4%), 0px 16px 24px rgb(96 97 112 / 16%);
  border-radius: $radius-large;
  max-width: 1086px;
  min-height: 675px;

  @include devices(medium) {
    top: 16px;
    margin: 0px 16px;
  }
}
